<template>
    <div>
        <DxButtonGroup
            :items="actionTypes"
            key-expr="key"
            @itemClick="setCurrentActionType"
            stylingMode="contained"
            selectionMode="single"
            class="mb-2 mt-n2"
        />
        <DxDrawer
            opened-state-mode="shrink"
            position="bottom"
            reveal-mode="expand"
            :opened="dxSelectedItems.length > 0"
            :close-on-outside-click="false"
            template="t-drawer-bulk-actions"
            class="drawer-container"
        >
            <template #t-drawer-bulk-actions>
                <div style="height: 180px; min-width: 700px">
                    <!-- Header -->
                    <h4 class="mt-4" v-if="selectedActionType === 'CREATEORDER'">
                        {{
                            dxSelectedItems?.length > 1
                                ? `${dxSelectedItems.length} technische Aufträge erstellen`
                                : "Technischen Auftrag erstellen"
                        }}
                    </h4>
                    <h4 class="mt-4" v-else>{{ drawerTitle }}</h4>

                    <!-- Body -->
                    <b-card class="px-3 py-2 mb-4" role="group" no-body>
                        <DxValidationGroup>
                            <div class="d-flex" ref="targetGroup">
                                <div
                                    class="mx-2"
                                    v-if="selectedActionType === 'AO' || selectedActionType === 'EXECUTIONTIME'"
                                >
                                    <label>Zeitraum<span class="text-danger">*</span></label>
                                    <DxDateRangeBox
                                        v-model="minMaxOrderDate.dateRange"
                                        label-mode="hidden"
                                        :startDate="minMaxOrderDate.minDate"
                                        :endDate="minMaxOrderDate.maxDate"
                                        styling-mode="outlined"
                                        class="date-picker-control"
                                        display-format="dd. MMMM yyyy"
                                        validation-message-mode="always"
                                        :calendarOptions="calendarOptions"
                                    >
                                        <DxValidator>
                                            <DxRangeRule
                                                :min="minDate"
                                                message="Der Ausführungszeitraum darf nicht in der Vergangenheit liegen"
                                            />
                                            <DxRequiredRule message="Zeitraum ist ein Pflichtfeld" />
                                        </DxValidator>
                                    </DxDateRangeBox>
                                </div>
                                <div class="d-inline-flex ml-auto mt-auto">
                                    <div v-if="selectedActionType === 'AO'">
                                        <b-button
                                            key="startAOJobCreateBulk"
                                            variant="primary"
                                            class="mx-2"
                                            :disabled="orderCreationDisabled()"
                                            @click="onClickStartAOJobCreateBulk"
                                        >
                                            AO Job mit {{ dxSelectedItems.length }}
                                            {{ dxSelectedItems.length > 1 ? "Aufträgen" : "Auftrag" }} starten
                                            <b-spinner v-if="isBusy" small />
                                        </b-button>
                                    </div>
                                </div>
                                <div
                                    class="d-inline-flex ml-auto mt-auto"
                                    v-if="selectedActionType === 'EXECUTIONTIME'"
                                >
                                    <div>
                                        <b-button
                                            key="setExecutionDateRangeBulk"
                                            variant="primary"
                                            class="mx-2"
                                            @click="onClickSetExecutionDateRangeBulk"
                                        >
                                            Ausführungszeitraum setzen
                                            <b-spinner v-if="isBusy" small />
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </DxValidationGroup>
                        <DxValidationGroup>
                            <div class="d-flex" ref="targetGroup" v-if="selectedActionType === 'TECHNICIAN'">
                                <div class="mr-2">
                                    <label>Firma<span class="text-danger">*</span></label>
                                    <DxSelectBox
                                        :items="companies"
                                        value-expr="companyID"
                                        text-field="name"
                                        required
                                        :input-attr="{ 'aria-label': 'company' }"
                                        display-expr="name"
                                        v-model="selectedCompanyID"
                                        @value-changed="onChangeCompany"
                                    >
                                        <DxValidator>
                                            <DxRequiredRule message="Firma ist ein Pflichtfeld" />
                                        </DxValidator>
                                    </DxSelectBox>
                                </div>
                                <div class="mr-2">
                                    <label>Leiter</label>
                                    <DxSelectBox
                                        :items="managers"
                                        value-expr="userID"
                                        text-field="fullName"
                                        required
                                        :input-attr="{ 'aria-label': 'manager' }"
                                        display-expr="fullName"
                                        v-model="selectedManagerID"
                                    >
                                        <DxValidator>
                                            <DxRequiredRule message="Leiter ist ein Pflichtfeld" />
                                        </DxValidator>
                                    </DxSelectBox>
                                </div>
                                <div class="mr-2">
                                    <label>Techniker</label>
                                    <DxSelectBox
                                        :items="technicians"
                                        value-expr="userID"
                                        text-field="fullName"
                                        required
                                        :input-attr="{ 'aria-label': 'technician' }"
                                        display-expr="fullName"
                                        v-model="selectedTechnicianID"
                                    >
                                        <DxValidator>
                                            <DxRequiredRule message="Techniker ist ein Pflichtfeld" />
                                        </DxValidator>
                                    </DxSelectBox>
                                </div>
                                <div class="d-inline-flex ml-auto mt-auto" v-if="selectedActionType === 'TECHNICIAN'">
                                    <div>
                                        <b-button
                                            key="setTechnicianBulk"
                                            variant="primary"
                                            class="mx-2"
                                            @click="onClickSetTechnicianBulk"
                                            :disabled="companies.length === 0"
                                        >
                                            Techniker ändern
                                            <b-spinner v-if="isBusy" small />
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </DxValidationGroup>
                        <div class="d-flex">
                            <div class="mr-2" v-if="selectedActionType === 'COMMUNICATIONTECHNOLOGY'">
                                <label>Kommunikationstechnologie</label>
                                <DxSelectBox
                                    :items="communicationTechnology"
                                    :show-clear-button="true"
                                    value-expr="id"
                                    text-field="name"
                                    required
                                    :input-attr="{ 'aria-label': 'communicationTechnology' }"
                                    display-expr="name"
                                    v-model="selectedCommunicationTechnology"
                                >
                                </DxSelectBox>
                            </div>
                            <div
                                class="d-inline-flex ml-auto mt-auto"
                                v-if="selectedActionType === 'COMMUNICATIONTECHNOLOGY'"
                            >
                                <div>
                                    <b-button
                                        key="setCommunicationTechnology"
                                        variant="primary"
                                        class="mx-2"
                                        @click="onClickSetCommunicationTechnology"
                                        >Kommunikationstechnologie ändern
                                        <b-spinner v-if="isBusy" small />
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <div class="d-inline-flex ml-auto mt-auto" v-if="selectedActionType === 'CREATEORDER'">
                            <div>
                                <b-button
                                    key="createOrderTechBulk"
                                    variant="primary"
                                    class="mx-2"
                                    @click="onClickAuftragCreateBulk"
                                >
                                    {{ dxSelectedItems.length }}
                                    {{ dxSelectedItems.length > 1 ? "Aufträge" : "Auftrag" }} erstellen
                                    <b-spinner v-if="isBusy" small />
                                </b-button>
                            </div>
                        </div>
                        <div
                            class="d-inline-flex ml-auto mt-auto"
                            v-if="selectedActionType === 'TECHORDERDELETE' || selectedActionType === 'PLANORDERDELETE'"
                        >
                            <div>
                                <b-button
                                    key="deleteOrderBulk"
                                    variant="primary"
                                    class="mx-2"
                                    @click="onClickDeleteAuftraegeBulk"
                                >
                                    {{ dxSelectedItems.length }}
                                    {{ dxSelectedItems.length > 1 ? "Aufträge" : "Auftrag" }} löschen
                                    <b-spinner v-if="isBusy" small />
                                </b-button>
                            </div>
                        </div>
                    </b-card>
                </div>
            </template>

            <!-- dx data grid -->
            <DxDataGrid
                ref="dxDataGridRef"
                class="grid-container"
                :data-source="dxData"
                :show-borders="true"
                :remote-operations="true"
                :allow-column-resizing="true"
                :allow-column-reordering="true"
                :allow-column-fixing="true"
                :show-row-lines="true"
                :show-column-lines="false"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :on-initialized="onInitialized"
                :filter-sync-enabled="true"
                :filter-value="dxFilterValue"
                @editor-preparing="onEditorPreparing"
                @selection-changed="onSelectionChanged"
                @cell-prepared="onCellPrepared"
                @exporting="onExporting"
            >
                <!-- dx global settings -->
                <DxLoadPanel :enabled="true" :showIndicator="true" :showPane="true" />
                <DxExport :enabled="true" :formats="['xlsx']" />
                <DxHeaderFilter :visible="true" :search="{ enabled: true }">
                    <DxSearch :enabled="true" :timeout="900" :editor-options="searchEditorOptions" mode="contains" />
                </DxHeaderFilter>

                <DxColumnChooser :enabled="true" />
                <DxColumnFixing :enabled="true" />

                <DxSelection
                    select-all-mode="allPages"
                    :deferred="true"
                    show-check-boxes-mode="always"
                    :mode="dxSelectionMode"
                    :allow-select-all="true"
                />
                <DxFilterPanel :visible="true" />
                <DxSearchPanel :visible="true" width="30vw" />
                <DxPaging :page-size="100" />
                <DxPager
                    :show-page-size-selector="true"
                    :allowed-page-sizes="[20, 50, 100, 1000]"
                    :show-navigation-buttons="true"
                    :show-info="true"
                />
                <DxGroupPanel :visible="true" />
                <DxGrouping :auto-expand-all="false" />
                <DxSummary>
                    <DxGroupItem column="hakID" summary-type="count" />
                </DxSummary>
                <DxToolbar>
                    <DxItem name="groupPanel" />
                    <DxItem location="after" widget="dxButton" :options="dxTioFilterClear" />
                    <DxItem widget="dxButton" :options="dxTioReload" />
                    <DxItem name="exportButton" :options="exportButtonOptions" />
                    <DxItem name="searchPanel" />
                </DxToolbar>
                <!-- columns -->
                <DxColumn
                    data-field="auftragID"
                    caption="Auftrag ID"
                    cell-template="ct-auftrag-id"
                    data-type="number"
                    alignment="left"
                    sort-order="desc"
                    :fixed="true"
                />
                <DxColumn
                    data-field="auftragStatusID"
                    caption="Status"
                    data-type="string"
                    cell-template="ct-auftrag-status-id"
                    :fixed="true"
                />
                <DxColumn caption="Hausanschluss (HA)" :fixed="true">
                    <DxColumn
                        data-field="hak.tp"
                        caption="TP"
                        data-type="string"
                        cell-template="ct-hak-tp"
                        :fixed="true"
                    />
                    <DxColumn
                        caption="NG"
                        :calculate-cell-value="getNetzgebietFromTp"
                        data-type="string"
                        :allow-search="false"
                        :fixed="true"
                    />
                    <DxColumn
                        data-field="netzebene"
                        caption="NE"
                        data-type="number"
                        :allow-search="false"
                        :fixed="true"
                    />
                    <DxColumn data-field="hak.city" caption="Ort" data-type="string" :fixed="true" />
                    <DxColumn data-field="hak.address" caption="Adresse" data-type="string" :fixed="true" />
                </DxColumn>

                <DxColumn caption="">
                    <DxColumn data-field="hak.standort" caption="Lage" data-type="string" />
                    <DxColumn caption="Karte" cell-template="ha-map-template" :allow-search="false" />
                    <DxColumn
                        data-field="rollouted"
                        caption="Gerolloutet"
                        data-type="string"
                        cell-template="rollouted-template"
                        :customize-text="convertToJaNeinTeilweise"
                        :allow-search="false"
                    />
                    <DxColumn data-field="hak.ts" caption="TS" data-type="string" />
                    <DxColumn data-field="tsDescription" caption="TS Bezeichnung" data-type="string" />
                    <DxColumn data-field="hak.vk" caption="VK" data-type="string" />
                    <DxColumn data-field="vkDescription" caption="VK Bezeichnung" data-type="string" />
                    <DxColumn data-field="tsVkPlc" caption="TS/VK PLC" data-type="string" :allow-search="false" />
                    <DxColumn
                        data-field="lwl"
                        caption="LWL"
                        data-type="string"
                        :customize-text="getLwlString"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="eth"
                        caption="ETH"
                        data-type="string"
                        :customize-text="formatCapitalizedString"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="backFreileitung"
                        caption="hinter Freileitung"
                        data-type="string"
                        :customize-text="formatCapitalizedString"
                        :allow-search="false"
                    />
                </DxColumn>
                <DxColumn caption="Kommunikationstechnologie">
                    <DxColumn
                        data-field="eaCommunicationTechnology"
                        caption="EasyAsset"
                        data-type="string"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="selectedCommunicationTechnology"
                        caption="Übersteuert"
                        data-type="string"
                        :allow-search="false"
                    />
                </DxColumn>
                <DxColumn caption="Leiter">
                    <DxColumn data-field="technicianCompanyName" caption="Firma" data-type="string" />
                    <DxColumn data-field="manager.fullName" caption="Name" data-type="string" />
                </DxColumn>
                <DxColumn caption="Techniker">
                    <DxColumn data-field="technician.fullName" caption="Name" data-type="string" />
                </DxColumn>
                <DxColumn caption="Zeitraum">
                    <DxColumn
                        data-field="fromDate"
                        caption="von"
                        data-type="date"
                        :format="dateFormat"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="toDate"
                        caption="bis"
                        data-type="date"
                        :format="dateFormat"
                        :allow-search="false"
                    />
                </DxColumn>
                <DxColumn caption="Ausführung">
                    <DxColumn
                        data-field="executionDatePlanned"
                        caption="geplant"
                        data-type="date"
                        :format="dateTimeFormat"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="executionDateAgreement"
                        caption="festgelegt von/ mit"
                        :customize-text="agreementTypeMapping"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="executionDate"
                        caption="effektiv"
                        data-type="date"
                        :format="dateTimeFormat"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="bemerkung"
                        caption="Bemerkung"
                        data-type="string"
                        cell-template="ct-bemerkung"
                    />
                    <DxColumn
                        caption="Ausführbarkeit"
                        data-field="installationPlanStatusAmpel"
                        cell-template="status-template"
                        :allow-search="false"
                    >
                        <DxHeaderFilter :data-source="installationPlanStatusAmpelFilter" />
                    </DxColumn>
                    <DxColumn data-field="nextAmplifyingNode" caption="Netzverstärkender TP" data-type="string" />
                    <DxColumn
                        :calculate-cell-value="getTypFromAmplifyingNode"
                        caption="Typ"
                        data-type="string"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="distNextAmplifyingNode"
                        caption="Distanz [m]"
                        data-type="string"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="rolloutSeconds"
                        caption="Dauer"
                        data-type="number"
                        :customize-text="getEstimatedInstallationTimeHours"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="geraetestandorte"
                        caption="GST gesamt"
                        data-type="number"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="directZaehler"
                        caption="Direktmess. Zähler"
                        data-type="number"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="breakerZaehlerDavon"
                        caption="davon Breaker"
                        data-type="number"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="wandlerZaehler"
                        caption="Wandlerzähler"
                        data-type="number"
                        :allow-search="false"
                    />
                    <DxColumn data-field="flexibilitaeten" caption="Flex" data-type="number" :allow-search="false" />
                    <DxColumn data-field="rEintern" caption="VK/TS/UW" data-type="string" :allow-search="false" />
                </DxColumn>
                <DxColumn caption="Infobrief">
                    <DxColumn data-field="infobriefStatus" caption="verschickt" data-type="string" />
                    <DxColumn
                        data-field="infobriefVon"
                        caption="Zeitraum von"
                        data-type="string"
                        :allow-search="false"
                    />
                    <DxColumn
                        data-field="infobriefBis"
                        caption="Zeitraum bis"
                        data-type="string"
                        :allow-search="false"
                    />
                </DxColumn>
                <!-- cell templates -->
                <template #rollouted-template="{ data }">
                    <div>
                        <font-awesome-icon
                            v-if="data.data.rollouted === null"
                            :icon="['fas', 'circle-exclamation']"
                            class="mx-1 text-danger"
                        />
                        <font-awesome-icon
                            v-if="data.data.rollouted === 0"
                            :icon="['fas', 'circle']"
                            class="mx-1 text-black-20"
                        />
                        <font-awesome-icon
                            v-else-if="data.data.rollouted === 1"
                            :icon="['fas', 'circle-half-stroke']"
                            class="mx-1 text-warning"
                        />
                        <font-awesome-icon
                            v-else-if="data.data.rollouted === 2"
                            :icon="['fas', 'circle-check']"
                            class="mx-1 text-success"
                        />
                        <span>{{ convertToJaNeinTeilweise(data) }}</span>
                    </div>
                </template>
                <template #ct-auftrag-id="{ data }">
                    <router-link
                        v-if="data.data.auftragID"
                        :title="'Auftrag Detail zu Auftrag ' + data.data.auftragID"
                        :to="{ name: 'order-details', params: { id: data.data.auftragID } }"
                    >
                        {{ data.data.auftragID }}
                    </router-link>
                </template>
                <template #ct-auftrag-status-id="{ data }">
                    <b-link
                        :href="`/orders/list?auftragId=${data.data.auftragID}`"
                        target="_blank"
                        v-if="data.data.auftragStatusID.toUpperCase() !== 'NEW'"
                    >
                        {{ data.data.auftragStatusID }}
                        <sup><font-awesome-icon :icon="['fas', 'up-right-from-square']"/></sup>
                    </b-link>
                    <span v-else>{{ data.data.auftragStatusID }}</span>
                </template>
                <template #ct-hak-tp="{ data }">
                    <router-link title="HAK Detail" :to="{ name: 'hak-details', params: { id: data.data.hakID } }">
                        {{ data.data.hak.tp }}
                    </router-link>
                </template>
                <template #ha-map-template="{ data }">
                    <router-link
                        title="HAK auf der Karte anzeigen"
                        :to="{ name: 'hak-map', params: { id: data.data.hakID } }"
                    >
                        <i class="fas fa-map-marked-alt"></i>
                    </router-link>
                </template>
                <template #status-template="{ data }">
                    <font-awesome-icon
                        v-b-tooltip.hover
                        :title="'Status Installationsplan: ' + data.data.installationPlanStatusRaw"
                        v-if="data.data.installationPlanStatusAmpel"
                        :icon="['fas', 'circle']"
                        :class="getStatusVariant(data.data.installationPlanStatusAmpel)"
                    />
                </template>
                <template #ct-bemerkung="{ data }">
                    <div v-html="data.data.bemerkung"></div>
                </template>
            </DxDataGrid>
        </DxDrawer>
    </div>
</template>

<script>
import { createStore } from "devextreme-aspnet-data-nojquery";
import { exportDataGrid } from "devextreme/excel_exporter";
import DxValidationGroup from "devextreme-vue/validation-group";
import {
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxDataGrid,
    DxExport,
    DxFilterPanel,
    DxGrouping,
    DxGroupItem,
    DxGroupPanel,
    DxHeaderFilter,
    DxItem,
    DxLoadPanel,
    DxPager,
    DxPaging,
    DxSearch,
    DxSearchPanel,
    DxSelection,
    DxSummary,
    DxToolbar,
} from "devextreme-vue/data-grid";
import DxDateRangeBox from "devextreme-vue/date-range-box";
import { DxValidator, DxRequiredRule, DxRangeRule } from "devextreme-vue/validator";
import DxDrawer from "devextreme-vue/drawer";
import DxSelectBox from "devextreme-vue/select-box";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import moment from "moment";

import constants from "@/constants/constants";
import gridService from "@/services/gridBaseService";
import processApi from "@/services/api/process.api";
import rolloutviewApi from "@/services/api/rolloutview.api";
import auftragsAPI from "@/services/api/auftrags.api";
import usersAPI from "@/services/api/users.api";
import companiesAPi from "@/services/api/companies.api";
import { DxButtonGroup } from "devextreme-vue/button-group";
import haksAPI from "@/services/api/haks.api";

export default {
    name: "AuftragPlanung",
    components: {
        DxButtonGroup,
        DxDrawer,
        DxDateRangeBox,
        DxValidator,
        DxRequiredRule,
        DxGroupPanel,
        DxSelectBox,
        DxItem,
        DxColumn,
        DxHeaderFilter,
        DxGroupItem,
        DxColumnFixing,
        DxPaging,
        DxFilterPanel,
        DxGrouping,
        DxSummary,
        DxPager,
        DxLoadPanel,
        DxSelection,
        DxSearch,
        DxColumnChooser,
        DxToolbar,
        DxDataGrid,
        DxSearchPanel,
        DxExport,
        DxRangeRule,
        DxValidationGroup,
    },
    computed: {
        selectedActionType() {
            return this.currentActionType;
        },
    },
    data() {
        return {
            isBusy: false,
            dateFormat: constants.DATE_FORMAT_GRID,
            dateTimeFormat: constants.DATETIME_FORMAT_GRID,
            dxData: null,
            dxDataGridRef: null,
            dxFilterValue: null,
            dxSelectedItems: [],
            dxSelectionMode: "never",
            dxTioFilterClear: {
                icon: "fas fa-filter-circle-xmark",
                hint: "Filter löschen",
                onClick: () => {
                    this.dxDataGridRef.clearFilter();
                    this.dxFilterValue = null;
                },
            },
            dxTioReload: {
                icon: "fas fa-rotate",
                hint: "Aktualisieren",
                onClick: () => {
                    this.dxDataGridRef.refresh();
                },
            },
            searchEditorOptions: {
                placeholder: "Search value",
                mode: "text",
            },
            minMaxOrderDate: {
                dateRange: [],
                minDate: null,
                maxDate: null,
            },
            exportButtonOptions: {
                icon: "fas fa-file-excel",
                hint: "Excel Export",
            },
            minDate: new Date(),
            acceptedStatesForSelection: [],
            allowedAuftragIdsToMultipleSelect: [],
            currentActionType: null,
            technicians: [{ userID: null, fullName: "" }],
            managers: [{ userID: null, fullName: "" }],
            companies: [],
            selectedTechnicianID: null,
            selectedManagerID: null,
            selectedCompanyID: null,
            selectedCommunicationTechnology: null,
            drawerTitle: "",
            communicationTechnology: Object.entries(constants.communicationTechnology).map(([name, id]) => ({
                id,
                name,
            })),
            actionTypes: [
                // Currently, we are disabling all functionality related to auftrags-optimierung (AO) by commenting out the action type.
                // If needed in the future, we can easily re-enable it by uncommenting the action type.
                // See bug on Azure DevOps #20042
                // {
                //     key: "AO",
                //     text: "Auftragsoptimierung starten",
                //     acceptedStates: ["NEW", "CREATING", "CREATED", "ASSIGNED", "ACCEPTED"],
                // },
                {
                    key: "CREATEORDER",
                    text: "Technischen Auftrag erstellen",
                    acceptedStates: ["NEW"],
                    type: "default",
                },
                {
                    key: "EXECUTIONTIME",
                    text: "Ausführungszeitraum setzen",
                    acceptedStates: ["NEW", "CREATING", "CREATED", "ASSIGNED"],
                    type: "default",
                },
                {
                    key: "COMMUNICATIONTECHNOLOGY",
                    text: "Kommunikationstechnologie ändern",
                    acceptedStates: ["NEW", "CREATING", "CREATED", "ASSIGNED", "ACCEPTED"],
                    type: "default",
                },
                {
                    key: "TECHNICIAN",
                    text: "Techniker ändern",
                    acceptedStates: ["NEW", "ERROR", "CREATED", "ASSIGNED", "ACCEPTED"],
                    type: "default",
                },
                {
                    key: "PLANORDERDELETE",
                    text: "Planungsaufträge löschen",
                    acceptedStates: ["NEW"],
                    type: "default",
                },
                {
                    key: "TECHORDERDELETE",
                    text: "Technische Aufträge löschen",
                    acceptedStates: ["CREATED", "ERROR"],
                    type: "default",
                },
            ],
            calendarOptions: {
                firstDayOfWeek: 1,
                disabledDates: (date) => this.isWeekend(date),
            },
            installationPlanStatusAmpelFilter: [
                {
                    text: "Rot",
                    value: constants.installationPlanStatusAmpel.RED,
                },
                {
                    text: "Gelb",
                    value: constants.installationPlanStatusAmpel.YELLOW,
                },
                {
                    text: "Grün",
                    value: constants.installationPlanStatusAmpel.GREEN,
                },
                {
                    text: "(Leerwerte)",
                    value: [["installationPlanStatusAmpel", "=", null], "or", ["installationPlanStatusAmpel", "=", ""]],
                },
            ],
        };
    },
    methods: {
        calculateSelectedItems() {
            this.dxDataGridRef.getSelectedRowsData().then((sel) => {
                sel = sel.filter((s) => this.acceptedStatesForSelection.includes(s.auftragStatusID.toUpperCase()));
                this.dxSelectedItems = sel;
                this.minMaxOrderDate = this.getMinMaxDateRangeOfSelectedOrderPlannings(this.dxSelectedItems);
                if (this.currentActionType === "TECHNICIAN" && this.dxSelectedItems.length > 0) {
                    this.loadCompanies(this.dxSelectedItems);
                }
            });
        },
        isWeekend(date) {
            const day = date.date.getDay();
            return day === 0 || day === 6;
        },
        setCurrentActionType(actionType) {
            if (this.currentActionType === actionType.itemData.key) return;
            this.dxSelectedItems = [];
            this.currentActionType = actionType.itemData.key;
            this.dxSelectionMode = "never";
            this.drawerTitle = actionType.itemData.text;

            setTimeout(() => {
                this.acceptedStatesForSelection = actionType.itemData.acceptedStates;
                if (this.acceptedStatesForSelection && this.acceptedStatesForSelection.length > 0) {
                    rolloutviewApi
                        .getAllowedAuftragIdsSelectionForAuftragView(this.acceptedStatesForSelection)
                        .then((resp) => (this.allowedAuftragIdsToMultipleSelect = resp.data));
                }
                this.dxSelectionMode = "multiple";
            });
        },
        onCellPrepared(e) {
            // hacky way to disable pointer events for rows that have no checkbox
            // "dx-command-select" is the class of the checkbox column and "dx-state-disabled dx-state-invisible" is the class of cells without checkboxes
            if (
                e.cellElement.className.includes("dx-command-select") &&
                e.cellElement?.children[0]?.className.includes("dx-state-disabled dx-state-invisible")
            ) {
                e.cellElement.style.pointerEvents = "none";
            }
        },
        onEditorPreparing(e) {
            switch (e.parentType) {
                case "dataRow":
                    if (!this.acceptedStatesForSelection.includes(e.row.data.auftragStatusID.toUpperCase())) {
                        // to "add" the checkboxes
                        e.editorOptions.disabled = true;
                        e.editorOptions.visible = false;
                    }
                    break;

                case "headerRow":
                    e.editorOptions.onInitialized = (eInitialized) => {
                        if (eInitialized.component) this.selectAllCheckBox = eInitialized.component;
                    };
                    e.editorOptions.onValueChanged = (eValueChanged) => {
                        if (
                            (eValueChanged.previousValue === false || eValueChanged.previousValue === undefined) &&
                            eValueChanged.value === true
                        ) {
                            const existingFilter = this.dxDataGridRef.getCombinedFilter();
                            if (existingFilter && existingFilter.length > 0) {
                                e.component.option("selectionFilter", [
                                    existingFilter,
                                    "and",
                                    this.getSelectedItemsFilter(),
                                ]);
                            } else {
                                e.component.option("selectionFilter", this.getSelectedItemsFilter());
                            }

                            this.selectAllCheckBox.option("value", true);
                        } else if (eValueChanged.previousValue === true && eValueChanged.value === false) {
                            e.component.option("selectionFilter", ["auftragID", "=", -1]);
                            e.component.deselectAll();
                        }
                    };
                    break;
                default:
                    // do nothing
                    break;
            }
        },
        onInitialized(e) {
            this.dxDataGridRef = e.component;
            this.loadDataGrid();
        },
        onSelectionChanged() {
            this.calculateSelectedItems();
        },
        convertToJaNeinTeilweise(cellInfo) {
            if (cellInfo.value === 0) {
                return "Nein";
            } else if (cellInfo.value === 1) {
                return "Teilweise";
            } else if (cellInfo.value === 2) {
                return "Ja";
            } else {
                return "HA nicht in IS-E";
            }
        },
        onExporting(e) {
            // Export the data to an Excel file
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Auftragsplanung");
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const now = moment().format(constants.DATETIME_FORMAT_FILE);
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        `${now}_REtasks_Auftragsplanung.xlsx`
                    );
                });
            });
        },
        loadDataGrid() {
            // create data store
            this.dxData = createStore(
                gridService.getStoreConfig({
                    key: "auftragID",
                    loadUrl: `${constants.env.API_ROOT_URL}/net/rolloutview/auftrag-view`,
                    loadParams: {
                        requireTotalCount: true,
                        requireGroupCount: true,
                    },
                })
            );
        },
        loadCompanies(selectedOrders) {
            const communityCodes = [...new Set(selectedOrders.map((order) => order.communityCode))];
            const params = new URLSearchParams();
            communityCodes.map((communityCode) => params.append("communityCodes", communityCode));
            companiesAPi.get(params).then((resp) => {
                this.companies = resp.data;
            });
        },
        loadManagers(companyId) {
            return usersAPI
                .get({
                    companyID: companyId,
                    roleID: constants.LEITER_ROLE_ID,
                })
                .then((resp) => {
                    this.managers = [];
                    resp.data.forEach((manager) =>
                        this.managers.push({
                            userID: manager.userID,
                            fullName: `${manager.firstName} ${manager.lastName}`,
                        })
                    );
                });
        },
        loadTechnicians(companyId) {
            return usersAPI
                .get({
                    companyID: companyId,
                    roleID: constants.TECHNICIAN_ROLE_ID,
                })
                .then((resp) => {
                    this.technicians = [];
                    resp.data.forEach((t) =>
                        this.technicians.push({ userID: t.userID, fullName: `${t.firstName} ${t.lastName}` })
                    );
                });
        },
        onChangeCompany() {
            if (this.selectedCompanyID) {
                this.loadManagers(this.selectedCompanyID);
                this.loadTechnicians(this.selectedCompanyID);
            }
        },
        onClickAuftragCreateBulk() {
            this.ordersCreate(this.dxSelectedItems);
        },
        onClickStartAOJobCreateBulk() {
            this.startAOJobForSelection(this.dxSelectedItems);
        },
        onClickSetCommunicationTechnology() {
            this.setCommunicationTechnologyBulk(this.dxSelectedItems);
        },
        onClickDeleteAuftraegeBulk() {
            this.deletOrdersBulk(this.dxSelectedItems);
        },
        onClickSetExecutionDateRangeBulk() {
            this.setExecutionDateRangeBulk(this.dxSelectedItems);
        },
        onClickSetTechnicianBulk() {
            this.setTechnicianBulk(this.dxSelectedItems);
        },
        setCommunicationTechnologyBulk(selectedOrders) {
            const payload = [];
            selectedOrders.forEach((order) => {
                if (this.acceptedStatesForSelection.includes(order.auftragStatusID.toUpperCase())) {
                    payload.push({
                        tp: order.hak.tp,
                        patchDoc: [
                            {
                                path: "/CommunicationTechnology",
                                value: this.selectedCommunicationTechnology,
                                op: "REPLACE",
                            },
                        ],
                    });
                }
            });
            haksAPI
                .bulkPatch(payload)
                .then(() => {
                    const msg_pluralized =
                        selectedOrders.length > 1
                            ? `für ${selectedOrders.length} Aufträge wurde geändert`
                            : "wurde geändert";
                    this.$bvToast.toast(`Die Kommunikationstechnologie ${msg_pluralized}.`, {
                        title: "SUCCESS",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        appendToast: true,
                    });
                    this.$refs.dxDataGridRef.instance.deselectAll();
                    this.dxDataGridRef.refresh().then(() => {
                        this.dxSelectedItems = [];
                    });
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        setTechnicianBulk(selectedOrders) {
            const payload = [];

            selectedOrders.forEach((order) => {
                let patchDocument = [
                    { path: "/technicianID", value: this.selectedTechnicianID, op: "REPLACE" },
                    { path: "/managerID", value: this.selectedManagerID, op: "REPLACE" },
                ];

                if (this.acceptedStatesForSelection.includes(order.auftragStatusID.toUpperCase())) {
                    if (order.auftragStatusID.toUpperCase() === "CREATED") {
                        patchDocument.push({ path: "/auftragStatusID", value: "assigned", op: "REPLACE" });
                    }
                    payload.push({
                        auftragID: order.auftragID,
                        patchDoc: patchDocument,
                    });
                }
            });
            auftragsAPI
                .bulkPatch(payload)
                .then(() => {
                    const msg_pluralized =
                        selectedOrders.length > 1
                            ? `für ${selectedOrders.length} Aufträge geändert worden`
                            : "geändert worden";
                    this.$bvToast.toast(`Die Firma, der Leiter und Techniker sind ${msg_pluralized}.`, {
                        title: "SUCCESS",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        appendToast: true,
                    });
                    this.$refs.dxDataGridRef.instance.deselectAll();
                    this.dxDataGridRef.refresh().then(() => {
                        this.dxSelectedItems = [];
                    });
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        setExecutionDateRangeBulk(selectedOrders) {
            const payload = [];
            selectedOrders.forEach((order) => {
                if (this.acceptedStatesForSelection.includes(order.auftragStatusID.toUpperCase())) {
                    payload.push({
                        auftragID: order.auftragID,
                        patchDoc: [
                            { path: "/FromDate", value: this.minMaxOrderDate.dateRange[0], op: "REPLACE" },
                            { path: "/ToDate", value: this.minMaxOrderDate.dateRange[1], op: "REPLACE" },
                        ],
                    });
                }
            });
            auftragsAPI
                .bulkPatch(payload)
                .then(() => {
                    const msg_pluralized =
                        selectedOrders.length > 1
                            ? `für ${selectedOrders.length} Aufträge wurde angepasst`
                            : "wurde angepasst";
                    this.$bvToast.toast(`Der Ausführungszeitraum ${msg_pluralized}.`, {
                        title: "SUCCESS",
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        appendToast: true,
                    });
                    this.$refs.dxDataGridRef.instance.deselectAll();
                    this.dxDataGridRef.refresh().then(() => {
                        this.dxSelectedItems = [];
                    });
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        ordersCreate(ordersToCreate) {
            let allowCreateOrders = false;
            const promsCreateOrders = [];

            const auftragIds = ordersToCreate
                .filter((order) => this.acceptedStatesForSelection.includes(order.auftragStatusID.toUpperCase()))
                .map((order) => order.auftragID);

            if (auftragIds.length > 50) {
                this.$bvModal
                    .msgBoxConfirm(
                        `Sie haben mehr als 50 Aufträge ausgewählt. Sind Sie sicher, dass Sie ${auftragIds.length} technische Aufträge erstellen möchten?`,
                        {
                            title: `Technische Aufträge erstellen`,
                            size: "md",
                            buttonSize: "sm",
                            okVariant: "danger",
                            okTitle: "Ja",
                            cancelTitle: "Nein",
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true,
                        }
                    )
                    .then((createData) => {
                        if (createData) {
                            allowCreateOrders = true;
                            this.isBusy = true;
                        }
                    });
            } else {
                allowCreateOrders = true;
                this.isBusy = true;
            }

            if (allowCreateOrders) {
                auftragIds.forEach((auftragId) => {
                    promsCreateOrders.push(processApi.createAuftrag({ auftragID: auftragId }));
                });
                // wait for all promises to complete
                Promise.all(promsCreateOrders)
                    .then((resp) => {
                        // filter all responses with status 201 (successfully created)
                        const respCreated = resp.filter((r) => r.status === 201);
                        const msg_pluralized = ordersToCreate.length > 1 ? "Aufträgen" : "Auftrag";
                        this.$bvToast.toast(
                            `Die Erstellung von ${respCreated.length} ROLLOUT ${msg_pluralized} wurde gestartet.`,
                            {
                                title: "SUCCESS",
                                variant: "success",
                                toaster: "b-toaster-bottom-right",
                                appendToast: true,
                            }
                        );
                        // refresh grid data
                        this.$refs.dxDataGridRef.instance.deselectAll();
                        this.dxDataGridRef.refresh().then(() => {
                            this.dxSelectedItems = [];
                        });
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
        },
        deletOrdersBulk(ordersToDelete) {
            const auftragIds = [];
            ordersToDelete.forEach((order) => {
                if (this.acceptedStatesForSelection.includes(order.auftragStatusID.toUpperCase())) {
                    auftragIds.push(order.auftragID);
                }
            });

            this.isBusy = true;
            const msg_pluralized_orderTech = auftragIds.length > 1 ? "technische Aufträge" : "technischen Auftrag";
            const msg_pluralized_orderPlan = auftragIds.length > 1 ? "Planungsaufträge" : "Planungsauftrag";
            const msgOrderType =
                this.selectedActionType === "TECHORDERDELETE" ? msg_pluralized_orderTech : msg_pluralized_orderPlan;
            this.$bvModal
                .msgBoxConfirm(`Möchten Sie wirklich ${ordersToDelete.length} ${msgOrderType} permanent löschen?`, {
                    title: `Aufträge löschen`,
                    size: "md",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Ja",
                    cancelTitle: "Nein",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((deleteData) => {
                    if (deleteData) {
                        auftragsAPI
                            .bulkDelete({ auftragIds: auftragIds })
                            .then(() => {
                                // display successful DELETE toast
                                if (ordersToDelete.length > 0) {
                                    this.$bvToast.toast(`Aufträge wurden gelöscht.`, {
                                        title: "SUCCESS",
                                        variant: "success",
                                        toaster: "b-toaster-bottom-right",
                                        appendToast: true,
                                    });
                                } else {
                                    this.$bvToast.toast(`Keine Aufträge zum Löschen ausgewählt.`, {
                                        title: "WARNUNG",
                                        variant: "warning",
                                        toaster: "b-toaster-bottom-right",
                                        appendToast: true,
                                    });
                                }
                                this.$refs.dxDataGridRef.instance.deselectAll();
                                this.dxDataGridRef.refresh().then(() => {
                                    this.dxSelectedItems = [];
                                });
                            })
                            .finally(() => {
                                this.isBusy = false;
                            });
                    } else {
                        this.isBusy = false;
                    }
                });
        },
        startAOJobForSelection(ordersToOptimize) {
            const orderIds = [];

            this.isBusy = true;

            ordersToOptimize.forEach((a) => {
                // restrict order creation to status NEW, CREATING, CREATED, ASSIGNED, ACCEPTED (also implemented in the endpoint to make sure this is not bypassed)
                if (this.acceptedStatesForSelection.includes(a.auftragStatusID.toUpperCase())) {
                    // push promise to promise container
                    orderIds.push(a.auftragID);
                }
            });

            const promsCreateOrders = {
                fromDate: this.minMaxOrderDate.dateRange[0],
                toDate: this.minMaxOrderDate.dateRange[1],
                auftragIds: orderIds,
            };
            if (promsCreateOrders.auftragIds.length > 0) {
                processApi
                    .startAuftragOptimisation(promsCreateOrders)
                    .then(() => {
                        this.isBusy = false;
                        const msg_pluralized = promsCreateOrders.auftragIds.length > 1 ? "Aufträgen" : "Auftrag";
                        this.$bvToast.toast(
                            `Der AO Job mit ${promsCreateOrders.auftragIds.length} ${msg_pluralized} wurde gestartet.`,
                            {
                                title: "SUCCESS",
                                variant: "success",
                                toaster: "b-toaster-bottom-right",
                                appendToast: true,
                            }
                        );
                        this.$refs.dxDataGridRef.instance.deselectAll();
                        this.dxDataGridRef.refresh().then(() => {
                            this.dxSelectedItems = [];
                        });
                    })
                    .catch(() => {
                        this.isBusy = false;
                    });
            } else {
                this.isBusy = false;
                this.$bvToast.toast(
                    `Es wurden keine Aufträge ausgewählt, die für die Auftragsoptimierung in Frage kommen.`,
                    {
                        title: "WARNUNG",
                        variant: "warning",
                        toaster: "b-toaster-bottom-right",
                        appendToast: true,
                    }
                );
                return;
            }
        },
        orderCreationDisabled() {
            return (
                this.minMaxOrderDate.dateRange[0] < this.minDate ||
                this.minMaxOrderDate.dateRange[1] < this.minDate ||
                this.isBusy
            );
        },
        formatCapitalizedString(cellInfo) {
            if (cellInfo.value === "NEIN" || cellInfo.value === "JA") {
                return cellInfo.value.charAt(0).toUpperCase() + cellInfo.value.slice(1).toLowerCase();
            }
            return cellInfo.value;
        },
        getEstimatedInstallationTimeHours(cellInfo) {
            // transform seconds to hh:mm format
            if (cellInfo.value) {
                let hours = Math.floor(cellInfo.value / 3600);
                let minutes = Math.floor((cellInfo.value % 3600) / 60);

                minutes = minutes < 10 ? `0${minutes}` : minutes;
                hours = hours < 10 ? `0${hours}` : hours;
                return `${hours}:${minutes}`;
            }
        },
        agreementTypeMapping(cellInfo) {
            if (Number.isInteger(cellInfo.value)) {
                // IMPORTANT: this is to apply the mapping to integer values only(!) -> (no null, no string, etc.)
                const atName = constants.selectableExecutionDateAgreementTypes.find(
                    (type) => type.value === cellInfo.value
                )?.text;
                return atName ? atName : `${cellInfo.value} - nicht definiert`;
            } else {
                // if no integer value is provided, return empty string
                return "";
            }
        },
        getLwlString(cellInfo) {
            const formattedString = this.formatCapitalizedString(cellInfo);

            if (formattedString === "Betriebsbereit") {
                return "Ja";
            }
            return formattedString;
        },
        getMinMaxDateRangeOfSelectedOrderPlannings(orderPlannings) {
            if (!orderPlannings || orderPlannings.length === 0) {
                return { minDate: null, maxDate: null, dateRange: [] };
            }

            const minMaxOrderDate = orderPlannings.reduce((accOrder, currentOrder) => {
                const fromDateCurrentOrder = new Date(currentOrder.fromDate);
                const toDateCurrentOrder = new Date(currentOrder.toDate);

                if (fromDateCurrentOrder < new Date(accOrder.minDate) || !accOrder.minDate) {
                    accOrder.minDate = fromDateCurrentOrder;
                }
                if (toDateCurrentOrder > new Date(accOrder.maxDate) || !accOrder.maxDate) {
                    accOrder.maxDate = toDateCurrentOrder;
                }
                return accOrder;
            }, {});

            if (minMaxOrderDate.minDate < this.minDate) {
                minMaxOrderDate.minDate = this.minDate;
            }
            // todo: what date should we set in that case? +1 day? 1 week?
            if (minMaxOrderDate.maxDate < minMaxOrderDate.minDate) {
                minMaxOrderDate.maxDate = this.minDate;
            }

            minMaxOrderDate.dateRange = [minMaxOrderDate.minDate, minMaxOrderDate.maxDate];
            return minMaxOrderDate;
        },
        getNetzgebietFromTp(dataRow) {
            //extracts the netzgebiet identifier from the tp
            if (dataRow.tp) {
                return dataRow.tp.split("-")[1];
            }
        },
        getSelectedItemsFilter() {
            const filter = [];
            this.allowedAuftragIdsToMultipleSelect.forEach((value, index) => {
                filter.push(["auftragID", "=", value]);
                if (index !== this.allowedAuftragIdsToMultipleSelect.length - 1) {
                    filter.push("or");
                }
            });
            return filter;
        },
        getStatusVariant(status) {
            switch (status) {
                case constants.installationPlanStatusAmpel.RED:
                    return "text-danger";
                case constants.installationPlanStatusAmpel.YELLOW:
                    return "text-warning";
                case constants.installationPlanStatusAmpel.GREEN:
                    return "text-success";
            }
        },
        getTypFromAmplifyingNode(dataRow) {
            if (dataRow && dataRow.nextAmplifyingNode && typeof dataRow.nextAmplifyingNode === "string") {
                const parts = dataRow.nextAmplifyingNode.split("-"); // REN-PR-TS10-0059 --> REN, PR, TS10, 0059
                if (parts.length > 2) {
                    const match = parts[2].match(/[a-zA-Z]{2}/); // TS10 --> TS
                    if (match) {
                        return match[0];
                    }
                }
            }

            return null;
        },
    },
    watch: {
        "minMaxOrderDate.dateRange": function(newDateRange) {
            //prevent selection of weekends
            if (newDateRange[0] && newDateRange[1]) {
                const startDay = newDateRange[0].getDay();
                const endDay = newDateRange[1].getDay();

                if (startDay === 6) {
                    // Saturday
                    newDateRange[0].setDate(newDateRange[0].getDate() + 2);
                } else if (startDay === 0) {
                    // Sunday
                    newDateRange[0].setDate(newDateRange[0].getDate() + 1);
                }

                if (endDay === 6) {
                    // Saturday
                    newDateRange[1].setDate(newDateRange[1].getDate() - 1);
                } else if (endDay === 0) {
                    // Sunday
                    newDateRange[1].setDate(newDateRange[1].getDate() - 2);
                }
                this.minMaxOrderDate.dateRange = newDateRange;
                this.minMaxOrderDate.minDate = newDateRange[0];
                this.minMaxOrderDate.maxDate = newDateRange[1];
            }
        },
    },
};
</script>
